import antiLaissezFaire from './write/antiLaissezFaire';
import approachWithLove from './write/approachWithLove';
import aStepInTheRightDirection from './write/aStepInTheRightDirection';
import be from './write/be';
import beginnersMind from './write/beginnersMind';
import beingHigh from './write/beingHigh';
import birthdayFeels from './write/birthdayFeels';
import blip from './write/blip';
import booksIReadIn2019 from './write/booksIReadIn2019';
import bornAgain from './write/bornAgain';
import consistency from './write/consistency';
import dontAskForItToBeEasy from './write/dontAskForItToBeEasy';
import doSomething from './write/doSomething';
import doTheOpposite from './write/doTheOpposite';
import doubt from './write/doubt';
import failure from './write/failure';
import familyMatters from './write/familyMatters';
import favorite from './write/favorite';
import fear from './write/fear';
import findingHappiness from './write/findingHappiness';
import goalsFor2020 from './write/goalsFor2020';
import goalsFor26 from './write/goalsFor26';
import goalsVsResolutions from './write/goalsVsResolutions';
import haveSomethingToDo from './write/haveSomethingToDo';
import haveYouEverTriedToBeARapper from './write/haveYouEverTriedToBeARapper';
import heaven from './write/heaven';
import heresTheThingTho from './write/heresTheThingTho';
import higherStandard from './write/higherStandard';
import howIRememberEveryThingWithTwos from './write/howIRememberEveryThingWithTwos';
import howPeopleWakeUpAt430AMToWork from './write/howPeopleWakeUpAt430AMToWork';
import howToBecomeAProgrammer from './write/howToBecomeAProgrammer';
import howToNotTakeThingsPersonally from './write/howToNotTakeThingsPersonally';
import howToRaiseTheCommunitysLevelOfIntelligence from './write/howToRaiseTheCommunitysLevelOfIntelligence';
import iAmCommittedTo from './write/iAmCommittedTo';
import interestingPeople from './write/interestingPeople';
import iThinkICan from './write/iThinkICan';
import layingOnACouch from './write/layingOnACouch';
import levels from './write/levels';
import liberty from './write/liberty';
import listenToYourself from './write/listenToYourself';
import loveVsFear from './write/loveVsFear';
import mVS from './write/mVS';
import newYearsResolutionsFor2020 from './write/newYearsResolutionsFor2020';
import nobodyGivesAFuck from './write/nobodyGivesAFuck';
import oneDayAtATime from './write/oneDayAtATime';
import onTheShouldersOfGiants from './write/onTheShouldersOfGiants';
import painAndPleasure from './write/painAndPleasure';
import pause from './write/pause';
import peopleChange from './write/peopleChange';
import posibility from './write/posibility';
import positiveThoughtExercises from './write/positiveThoughtExercises';
import potential from './write/potential';
import readThisIfYouAreStuck from './write/readThisIfYouAreStuck';
import realizationsFromRealNegotiations from './write/realizationsFromRealNegotiations';
import reasonsToUseAware from './write/reasonsToUseAware';
import reasonsToUseMuse from './write/reasonsToUseMuse';
import reasonsWhyYouShouldLearnHowToProgram from './write/reasonsWhyYouShouldLearnHowToProgram';
import resolution from './write/resolution';
import sacrifice from './write/sacrifice';
import seeWhatYouWantToSee from './write/seeWhatYouWantToSee';
import selfLove from './write/selfLove';
import solitude from './write/solitude';
import theBestCaliforniaBurritoInSanDiego from './write/theBestCaliforniaBurritoInSanDiego';
import theLongGame from './write/theLongGame';
import theOptimalState from './write/theOptimalState';
import thePoint from './write/thePoint';
import time from './write/time';
import timeHealsAll from './write/timeHealsAll';
import top10BenefitsOfWritingThingsDown from './write/top10BenefitsOfWritingThingsDown';
import waterYourGarden from './write/waterYourGarden';
import waysToStopForgettingThingsWithTwos from './write/waysToStopForgettingThingsWithTwos';
import weEndedUpNextToEachOther from './write/weEndedUpNextToEachOther';
import whatDoWeNeed from './write/whatDoWeNeed';
import whatDoYouSee from './write/whatDoYouSee';
import whatHappensWhenWeSlowDown from './write/whatHappensWhenWeSlowDown';
import whatIsMeaningful from './write/whatIsMeaningful';
import whatMakesYouFeelGreat from './write/whatMakesYouFeelGreat';
import whatWillBe from './write/whatWillBe';
import whatYouLove from './write/whatYouLove';
import whatYouWant from './write/whatYouWant';
import whenYoureFeelingDown from './write/whenYoureFeelingDown';
import whyDoWeHurtOurselves from './write/whyDoWeHurtOurselves';
import whyEverythingIsTheGreatest from './write/whyEverythingIsTheGreatest';
import whyIBuiltAware from './write/whyIBuiltAware';
import whyILeftMyDreamJobAtGoogleForTwos from './write/whyILeftMyDreamJobAtGoogleForTwos';
import whyMe from './write/whyMe';
import youAreYou from './write/youAreYou';
import youAreYouBecauseOfYou from './write/youAreYouBecauseOfYou';
import youCanDoIt from './write/youCanDoIt';
import youCanLearnAnything from './write/youCanLearnAnything';

import aboveTheLine from './notes/aboveTheLine';
import aBriefHistoryOfTime from './notes/aBriefHistoryOfTime';
import aCuriousMind from './notes/aCuriousMind';
import aGuideToTheGoodLife from './notes/aGuideToTheGoodLife';
import algorithmsToLiveBy from './notes/algorithmsToLiveBy';
import alibaba from './notes/alibaba';
import alreadyFree from './notes/alreadyFree';
import aMillionMilesInAThousandYears from './notes/aMillionMilesInAThousandYears';
import ampItUp from './notes/ampItUp';
import amusingOurselvesToDeath from './notes/amusingOurselvesToDeath';
import aNewEarth from './notes/aNewEarth';
import antifragile from './notes/antifragile';
import anythingYouWant from './notes/anythingYouWant';
import asAManThinketh from './notes/asAManThinketh';
import asAManThinketh2 from './notes/asAManThinketh2';
import askAndItIsGiven from './notes/askAndItIsGiven';
import aShortHistoryOfNearlyEverything from './notes/aShortHistoryOfNearlyEverything';
import atlasShrugged from './notes/atlasShrugged';
import atomicHabits from './notes/atomicHabits';
import attached from './notes/attached';
import awakenTheGiantWithin from './notes/awakenTheGiantWithin';
import awareness from './notes/awareness';
import backable from './notes/backable';
import barbarianDays from './notes/barbarianDays';
import becomingWise from './notes/becomingWise';
import benjaminFranklin from './notes/benjaminFranklin';
import bhagavadGita from './notes/bhagavadGita';
import bigBets from './notes/bigBets';
import bigMagic from './notes/bigMagic';
import birdByBird from './notes/birdByBird';
import blueOceanStrategy from './notes/blueOceanStrategy';
import braveNewWorld from './notes/braveNewWorld';
import breakthroughAdvertising from './notes/breakthroughAdvertising';
import buddhismWithoutBeliefs from './notes/buddhismWithoutBeliefs';
import buildingASecondBrain from './notes/buildingASecondBrain';
import buildingAStoryBrand from './notes/buildingAStoryBrand';
import builtToLast from './notes/builtToLast';
import calypso from './notes/calypso';
import cantHurtMe from './notes/cantHurtMe';
import chooseYourself from './notes/chooseYourself';
import cleanCode from './notes/cleanCode';
import codeComplete from './notes/codeComplete';
import commonSense from './notes/commonSense';
import conceptualBlockbusting from './notes/conceptualBlockbusting';
import conscious from './notes/conscious';
import contagious from './notes/contagious';
import courageIsCalling from './notes/courageIsCalling';
import crackingTheCodingInterview from './notes/crackingTheCodingInterview';
import creativityInc from './notes/creativityInc';
import crossingTheChasm from './notes/crossingTheChasm';
import crushIt from './notes/crushIt';
import cssSecrets from './notes/cssSecrets';
import dareToLead from './notes/dareToLead';
import decoded from './notes/decoded';
import deepWork from './notes/deepWork';
import deliveringHappiness from './notes/deliveringHappiness';
import designAsArt from './notes/designAsArt';
import designPatternsByTutorials from './notes/designPatternsByTutorials';
import dontLoseYourMindLoseYourWeight from './notes/dontLoseYourMindLoseYourWeight';
import dontMakeMeThink from './notes/dontMakeMeThink';
import dontWorryMakeMoney from './notes/dontWorryMakeMoney';
import dopamineNation from './notes/dopamineNation';
import droppingAshesOnTheBuddha from './notes/droppingAshesOnTheBuddha';
import effectiveObjectiveC20 from './notes/effectiveObjectiveC20';
import egoIsTheEnemy from './notes/egoIsTheEnemy';
import elonMusk from './notes/elonMusk';
import eloquentJavaScript from './notes/eloquentJavaScript';
import emotionalIntelligence from './notes/emotionalIntelligence';
import emotionalIntelligence20 from './notes/emotionalIntelligence20';
import enchiridion from './notes/enchiridion';
import essentialism from './notes/essentialism';
import everybodyWrites from './notes/everybodyWrites';
import exitRight from './notes/exitRight';
import expertSecrets from './notes/expertSecrets';
import extraordinaryGolf from './notes/extraordinaryGolf';
import fatLossForever from './notes/fatLossForever';
import fitForPurpose from './notes/fitForPurpose';
import flashBoys from './notes/flashBoys';
import flow from './notes/flow';
import fourThousandWeeks from './notes/fourThousandWeeks';
import gettingReal from './notes/gettingReal';
import gettingThingsDone from './notes/gettingThingsDone';
import gettingToYes from './notes/gettingToYes';
import goodProfit from './notes/goodProfit';
import goodToGreat from './notes/goodToGreat';
import greenlights from './notes/greenlights';
import gridSystemsInGraphicDesign from './notes/gridSystemsInGraphicDesign';
import grit from './notes/grit';
import hatchingTwitter from './notes/hatchingTwitter';
import healingBackPain from './notes/healingBackPain';
import highOutputManagement from './notes/highOutputManagement';
import hillbillyElegy from './notes/hillbillyElegy';
import homeIsWhereWeStartFrom from './notes/homeIsWhereWeStartFrom';
import hooked from './notes/hooked';
import howGoogleWorks from './notes/howGoogleWorks';
import howNotToDie from './notes/howNotToDie';
import howToHustleAndWin from './notes/howToHustleAndWin';
import howToReadABook from './notes/howToReadABook';
import howToSellAnythingToAnyone from './notes/howToSellAnythingToAnyone';
import howToStopWorryingAndStartLiving from './notes/howToStopWorryingAndStartLiving';
import howToTakeSmartNotes from './notes/howToTakeSmartNotes';
import howToWinFriendsAndInfluencePeople from './notes/howToWinFriendsAndInfluencePeople';
import howWillYouMeasureYourLife from './notes/howWillYouMeasureYourLife';
import htmlCss from './notes/htmlCss';
import ikigai from './notes/ikigai';
import infiniteJest from './notes/infiniteJest';
import influence from './notes/influence';
import inPieces from './notes/inPieces';
import inPraiseOfShadows from './notes/inPraiseOfShadows';
import inSearchOfExcellence from './notes/inSearchOfExcellence';
import insight from './notes/insight';
import inspired from './notes/inspired';
import introductionToAlgorithms from './notes/introductionToAlgorithms';
import iOSProgramming from './notes/iOSProgramming';
import javaScriptTheDefinitiveGuide from './notes/javaScriptTheDefinitiveGuide';
import jonathanLivingstonSeagull from './notes/jonathanLivingstonSeagull';
import learningCocoaWithObjectiveC from './notes/learningCocoaWithObjectiveC';
import learningNodejs from './notes/learningNodejs';
import lettersFromAStoic from './notes/lettersFromAStoic';
import loneSurvivor from './notes/loneSurvivor';
import lordOfTheFlies from './notes/lordOfTheFlies';
import loveYourselfLikeYourLifeDependsOnIt from './notes/loveYourselfLikeYourLifeDependsOnIt';
import madeInAmerica from './notes/madeInAmerica';
import madeToStick from './notes/madeToStick';
import makeTime from './notes/makeTime';
import makeYourBed from './notes/makeYourBed';
import makeYourMark from './notes/makeYourMark';
import mansSearchForMeaning from './notes/mansSearchForMeaning';
import mastersOfDoom from './notes/mastersOfDoom';
import meditations from './notes/meditations';
import memoirsOfAGeisha from './notes/memoirsOfAGeisha';
import mindset from './notes/mindset';
import mythicalManMonth from './notes/mythicalManMonth';
import neverEatAlone from './notes/neverEatAlone';
import neverSplitTheDifference from './notes/neverSplitTheDifference';
import objectiveCProgramming from './notes/objectiveCProgramming';
import objectOrientedJavaScript from './notes/objectOrientedJavaScript';
import offTheBeatenTrail from './notes/offTheBeatenTrail';
import onTheShortnessOfLife from './notes/onTheShortnessOfLife';
import originals from './notes/originals';
import outliers from './notes/outliers';
import peaceIsEveryStep from './notes/peaceIsEveryStep';
import perennialSeller from './notes/perennialSeller';
import perfectlyYourself from './notes/perfectlyYourself';
import plantBasedHighProteinCookbook from './notes/plantBasedHighProteinCookbook';
import playBigger from './notes/playBigger';
import poorCharliesAlmanack from './notes/poorCharliesAlmanack';
import pourYourHeartIntoIt from './notes/pourYourHeartIntoIt';
import principles from './notes/principles';
import productLedGrowth from './notes/productLedGrowth';
import programmingInterviewsExposed from './notes/programmingInterviewsExposed';
import programmingPearls from './notes/programmingPearls';
import purpleCow from './notes/purpleCow';
import puttingOutOfYourMind from './notes/puttingOutOfYourMind';
import quiet from './notes/quiet';
import radicalAcceptance from './notes/radicalAcceptance';
import radicalCandor from './notes/radicalCandor';
import radicalHope from './notes/radicalHope';
import range from './notes/range';
import ravingFans from './notes/ravingFans';
import readyForAnything from './notes/readyForAnything';
import rebirth from './notes/rebirth';
import relentless from './notes/relentless';
import resistingHappiness from './notes/resistingHappiness';
import rework from './notes/rework';
import richDadPoorDad from './notes/richDadPoorDad';
import rulesForLife from './notes/12RulesForLife';
import sapiens from './notes/sapiens';
import searchInsideYourself from './notes/searchInsideYourself';
import secretSauce from './notes/secretSauce';
import seeDoRepeat from './notes/seeDoRepeat';
import selfReliance from './notes/selfReliance';
import shapeUp from './notes/shapeUp';
import shoeDog from './notes/shoeDog';
import showYourWork from './notes/showYourWork';
import siddhartha from './notes/siddhartha';
import slicingPieHandbook from './notes/slicingPieHandbook';
import smarterFasterBetter from './notes/smarterFasterBetter';
import soGoodTheyCantIgnoreYou from './notes/soGoodTheyCantIgnoreYou';
import solveForHappy from './notes/solveForHappy';
import sprint from './notes/sprint';
import startWithWhy from './notes/startWithWhy';
import steveJobs from './notes/steveJobs';
import stillnessIsTheKey from './notes/stillnessIsTheKey';
import talkingToHumans from './notes/talkingToHumans';
import talkLikeTed from './notes/talkLikeTed';
import taoTeChing from './notes/taoTeChing';
import tenPercentHappier from './notes/tenPercentHappier';
import the10XRule from './notes/the10XRule';
import the22ImmutableLawsOfMarketing from './notes/the22ImmutableLawsOfMarketing';
import the48LawsOfPower from './notes/the48LawsOfPower';
import the4HourBody from './notes/the4HourBody';
import the4HourChef from './notes/the4HourChef';
import the4HourWorkweek from './notes/the4HourWorkweek';
import the7HabitsOfHighlyEffectivePeople from './notes/the7HabitsOfHighlyEffectivePeople';
import theAdvantage from './notes/theAdvantage';
import theAlchemist from './notes/theAlchemist';
import theAllegoryOfTheCave from './notes/theAllegoryOfTheCave';
import theArtOfAsking from './notes/theArtOfAsking';
import theArtOfLearning from './notes/theArtOfLearning';
import theArtOfStartupFundraising from './notes/theArtOfStartupFundraising';
import theArtOfTheDeal from './notes/theArtOfTheDeal';
import theArtOfWar from './notes/theArtOfWar';
import theArtSpirit from './notes/theArtSpirit';
import theAutobiographyOfAYogi from './notes/theAutobiographyOfAYogi';
import theBigShort from './notes/theBigShort';
import theBodyKeepsTheScore from './notes/theBodyKeepsTheScore';
import theBulletJournalMethod from './notes/theBulletJournalMethod';
import theCatcherInTheRye from './notes/theCatcherInTheRye';
import theCelestineProphecy from './notes/theCelestineProphecy';
import theChecklistManifesto from './notes/theChecklistManifesto';
import theChinaStudy from './notes/theChinaStudy';
import theCircle from './notes/theCircle';
import theCleanCoder from './notes/theCleanCoder';
import theColdStartProblem from './notes/theColdStartProblem';
import theCourageToBeDisliked from './notes/theCourageToBeDisliked';
import theCreativeAct from './notes/theCreativeAct';
import theCultureCode from './notes/theCultureCode';
import theDailyStoic from './notes/theDailyStoic';
import theDefiningDecade from './notes/theDefiningDecade';
import theDesignOfEverydayThings from './notes/theDesignOfEverydayThings';
import theDip from './notes/theDip';
import theDoorsOfPerception from './notes/theDoorsOfPerception';
import theEffectiveExecutive from './notes/theEffectiveExecutive';
import theElementsOfProgrammingStyle from './notes/theElementsOfProgrammingStyle';
import theElementsOfStyle from './notes/theElementsOfStyle';
import theEMythRevisited from './notes/theEMythRevisited';
import theEverythingStore from './notes/theEverythingStore';
import theFirstAndLastFreedom from './notes/theFirstAndLastFreedom';
import theFiveDysfunctionsOfATeam from './notes/theFiveDysfunctionsOfATeam';
import theFourAgreements from './notes/theFourAgreements';
import theFourAgreements2 from './notes/theFourAgreements2';
import theFourStepsToEpiphany from './notes/theFourStepsToEpiphany';
import theGiver from './notes/theGiver';
import theGoal from './notes/theGoal';
import theGoGiver from './notes/theGoGiver';
import theGreatestSalesmanInTheWorld from './notes/theGreatestSalesmanInTheWorld';
import theGreatGatsby from './notes/theGreatGatsby';
import theHappinessAdvantage from './notes/theHappinessAdvantage';
import theHardThingAboutHardThings from './notes/theHardThingAboutHardThings';
import theHitchhikersGuideToTheGalaxy from './notes/theHitchhikersGuideToTheGalaxy';
import theInnerGameOfTennis from './notes/theInnerGameOfTennis';
import theInnovators from './notes/theInnovators';
import theInnovatorsDilemma from './notes/theInnovatorsDilemma';
import theIntelligentInvestor from './notes/theIntelligentInvestor';
import theIOSInterviewGuide from './notes/theIOSInterviewGuide';
import theLastLecture from './notes/theLastLecture';
import theLeanStartup from './notes/theLeanStartup';
import theLittlePrince from './notes/theLittlePrince';
import theMagicOfThinkingBig from './notes/theMagicOfThinkingBig';
import theMarketingBlueprint from './notes/theMarketingBlueprint';
import theMartian from './notes/theMartian';
import theMillionaireNextDoor from './notes/theMillionaireNextDoor';
import theMomTest from './notes/theMomTest';
import theMonkWhoSoldHisFerrari from './notes/theMonkWhoSoldHisFerrari';
import theMountainIsYou from './notes/theMountainIsYou';
import theObstacleIsTheWay from './notes/theObstacleIsTheWay';
import theOldManAndTheSea from './notes/theOldManAndTheSea';
import theOneMinuteManager from './notes/theOneMinuteManager';
import theOneThing from './notes/theOneThing';
import thePowerLaw from './notes/thePowerLaw';
import thePowerOfHabit from './notes/thePowerOfHabit';
import thePowerOfMyth from './notes/thePowerOfMyth';
import thePowerOfNow from './notes/thePowerOfNow';
import thePowerOfPositiveThinking from './notes/thePowerOfPositiveThinking';
import thePowerOfWritingItDown from './notes/thePowerOfWritingItDown';
import thePracticeOfProgramming from './notes/thePracticeOfProgramming';
import thePragmaticProgrammer from './notes/thePragmaticProgrammer';
import theProphet from './notes/theProphet';
import thePurposeDrivenLife from './notes/thePurposeDrivenLife';
import theRemainsOfTheDay from './notes/theRemainsOfTheDay';
import theRhythmOfLife from './notes/theRhythmOfLife';
import theRichestManInBabylon from './notes/theRichestManInBabylon';
import theRoad from './notes/theRoad';
import theRoadToCharacter from './notes/theRoadToCharacter';
import theRuthlessEliminationOfHurry from './notes/theRuthlessEliminationOfHurry';
import theScienceOfHitting from './notes/theScienceOfHitting';
import theScoreTakesCareOfItself from './notes/theScoreTakesCareOfItself';
import theSearchForTruth from './notes/theSearchForTruth';
import theSecret from './notes/theSecret';
import theSevenSpiritualLawsOfSuccess from './notes/theSevenSpiritualLawsOfSuccess';
import theStoicChallenge from './notes/theStoicChallenge';
import theSubtleArtOfNotGivingAFck from './notes/theSubtleArtOfNotGivingAFck';
import theSurrenderExperiment from './notes/theSurrenderExperiment';
import theSwerve from './notes/theSwerve';
import theTaoOfHealthSexAndLongevity from './notes/theTaoOfHealthSexAndLongevity';
import theTaoOfPooh from './notes/theTaoOfPooh';
import theTaoOfWu from './notes/theTaoOfWu';
import theTB12Method from './notes/theTB12Method';
import theThirdDoor from './notes/theThirdDoor';
import theTimelessWayOfBuilding from './notes/theTimelessWayOfBuilding';
import theTippingPoint from './notes/theTippingPoint';
import theUltimateAnabolicCookbook from './notes/theUltimateAnabolicCookbook';
import theUnbearableLightnessOfBeing from './notes/theUnbearableLightnessOfBeing';
import theUntetheredSoul from './notes/theUntetheredSoul';
import theVirginWay from './notes/theVirginWay';
import theWarOfArt from './notes/theWarOfArt';
import theWayOfTheSuperiorMan from './notes/theWayOfTheSuperiorMan';
import theWayOfZen from './notes/theWayOfZen';
import theWayToLove from './notes/theWayToLove';
import theWisdomOfLife from './notes/theWisdomOfLife';
import theWrightBrothers from './notes/theWrightBrothers';
import thingsFallApart from './notes/thingsFallApart';
import thinkAndGrowRich from './notes/thinkAndGrowRich';
import thinkingFastAndSlow from './notes/thinkingFastAndSlow';
import thinkOnTheseThings from './notes/thinkOnTheseThings';
import thisIsMarketing from './notes/thisIsMarketing';
import tinyHabits from './notes/tinyHabits';
import titlesRuinEverything from './notes/titlesRuinEverything';
import toKillAMockingBird from './notes/toKillAMockingBird';
import toolsOfTitans from './notes/toolsOfTitans';
import traction from './notes/traction';
import tribe from './notes/tribe';
import trustMeImLying from './notes/trustMeImLying';
import tuesdaysWithMorrie from './notes/tuesdaysWithMorrie';
import understandingHumanNature from './notes/understandingHumanNature';
import vagabonding from './notes/vagabonding';
import ventureDeals from './notes/ventureDeals';
import wabiSabi from './notes/wabiSabi';
import wakingUp from './notes/wakingUp';
import whatITalkAboutWhenITalkAboutRunning from './notes/whatITalkAboutWhenITalkAboutRunning';
import whenThingsFallApart from './notes/whenThingsFallApart';
import whereTheCrawdadsSing from './notes/whereTheCrawdadsSing';
import whereverYouGoThereYouAre from './notes/whereverYouGoThereYouAre';
import white from './notes/white';
import whiteFragility from './notes/whiteFragility';
import who from './notes/who';
import whoMovedMyCheese from './notes/whoMovedMyCheese';
import whyWerePolarized from './notes/whyWerePolarized';
import whyWeSleep from './notes/whyWeSleep';
import willpower from './notes/willpower';
import wooden from './notes/wooden';
import writeItDownMakeItHappen from './notes/writeItDownMakeItHappen';
import zenMindBeginnersMind from './notes/zenMindBeginnersMind';
import zenToDone from './notes/zenToDone';
import zeroToOne from './notes/zeroToOne';

import levelsDeep from './apps/7LevelsDeep';
import aware from './apps/aware';
import muse from './apps/muse';
// import nsm from './apps/nsm';
import tens from './apps/tens';
import twos from './apps/twos';

import google from './work/google';
import trustwork from './work/trustwork';
import teradata from './work/teradata';
import qualcomm from './work/qualcomm';
import qualcommInternship from './work/qualcommInternship';

export const work = [twos, google, trustwork, qualcomm, qualcommInternship, teradata];

export const apps = [twos, aware, muse, levelsDeep, tens];

export const writings = [
  antiLaissezFaire,
  approachWithLove,
  aStepInTheRightDirection,
  be,
  beginnersMind,
  beingHigh,
  birthdayFeels,
  blip,
  booksIReadIn2019,
  bornAgain,
  consistency,
  dontAskForItToBeEasy,
  doSomething,
  doTheOpposite,
  doubt,
  failure,
  familyMatters,
  favorite,
  fear,
  findingHappiness,
  goalsFor2020,
  goalsFor26,
  goalsVsResolutions,
  haveSomethingToDo,
  haveYouEverTriedToBeARapper,
  heaven,
  heresTheThingTho,
  higherStandard,
  howIRememberEveryThingWithTwos,
  howPeopleWakeUpAt430AMToWork,
  howToBecomeAProgrammer,
  howToNotTakeThingsPersonally,
  howToRaiseTheCommunitysLevelOfIntelligence,
  iAmCommittedTo,
  interestingPeople,
  iThinkICan,
  layingOnACouch,
  levels,
  liberty,
  listenToYourself,
  loveVsFear,
  mVS,
  newYearsResolutionsFor2020,
  nobodyGivesAFuck,
  oneDayAtATime,
  onTheShouldersOfGiants,
  painAndPleasure,
  pause,
  peopleChange,
  posibility,
  positiveThoughtExercises,
  potential,
  readThisIfYouAreStuck,
  realizationsFromRealNegotiations,
  reasonsToUseAware,
  reasonsToUseMuse,
  reasonsWhyYouShouldLearnHowToProgram,
  resolution,
  sacrifice,
  seeWhatYouWantToSee,
  selfLove,
  solitude,
  theBestCaliforniaBurritoInSanDiego,
  theLongGame,
  theOptimalState,
  thePoint,
  time,
  timeHealsAll,
  top10BenefitsOfWritingThingsDown,
  waterYourGarden,
  waysToStopForgettingThingsWithTwos,
  weEndedUpNextToEachOther,
  whatDoWeNeed,
  whatDoYouSee,
  whatHappensWhenWeSlowDown,
  whatIsMeaningful,
  whatMakesYouFeelGreat,
  whatWillBe,
  whatYouLove,
  whatYouWant,
  whenYoureFeelingDown,
  whyDoWeHurtOurselves,
  whyEverythingIsTheGreatest,
  whyIBuiltAware,
  whyILeftMyDreamJobAtGoogleForTwos,
  whyMe,
  youAreYou,
  youAreYouBecauseOfYou,
  youCanDoIt,
  youCanLearnAnything
];

export const books = [
  aboveTheLine,
  aBriefHistoryOfTime,
  aCuriousMind,
  aGuideToTheGoodLife,
  algorithmsToLiveBy,
  alibaba,
  alreadyFree,
  aMillionMilesInAThousandYears,
  ampItUp,
  amusingOurselvesToDeath,
  aNewEarth,
  antifragile,
  anythingYouWant,
  asAManThinketh,
  asAManThinketh2,
  askAndItIsGiven,
  aShortHistoryOfNearlyEverything,
  atlasShrugged,
  atomicHabits,
  attached,
  awakenTheGiantWithin,
  awareness,
  backable,
  barbarianDays,
  becomingWise,
  benjaminFranklin,
  bhagavadGita,
  bigBets,
  bigMagic,
  birdByBird,
  blueOceanStrategy,
  braveNewWorld,
  breakthroughAdvertising,
  buddhismWithoutBeliefs,
  buildingASecondBrain,
  buildingAStoryBrand,
  builtToLast,
  calypso,
  cantHurtMe,
  chooseYourself,
  cleanCode,
  codeComplete,
  commonSense,
  conceptualBlockbusting,
  conscious,
  contagious,
  courageIsCalling,
  crackingTheCodingInterview,
  creativityInc,
  crossingTheChasm,
  crushIt,
  cssSecrets,
  dareToLead,
  decoded,
  deepWork,
  deliveringHappiness,
  designAsArt,
  designPatternsByTutorials,
  dontLoseYourMindLoseYourWeight,
  dontMakeMeThink,
  dontWorryMakeMoney,
  dopamineNation,
  droppingAshesOnTheBuddha,
  effectiveObjectiveC20,
  egoIsTheEnemy,
  elonMusk,
  eloquentJavaScript,
  emotionalIntelligence,
  emotionalIntelligence20,
  enchiridion,
  essentialism,
  everybodyWrites,
  exitRight,
  expertSecrets,
  extraordinaryGolf,
  fatLossForever,
  fitForPurpose,
  flashBoys,
  flow,
  fourThousandWeeks,
  gettingReal,
  gettingThingsDone,
  gettingToYes,
  goodProfit,
  goodToGreat,
  greenlights,
  gridSystemsInGraphicDesign,
  grit,
  hatchingTwitter,
  healingBackPain,
  highOutputManagement,
  hillbillyElegy,
  homeIsWhereWeStartFrom,
  hooked,
  howGoogleWorks,
  howNotToDie,
  howToHustleAndWin,
  howToReadABook,
  howToSellAnythingToAnyone,
  howToStopWorryingAndStartLiving,
  howToTakeSmartNotes,
  howToWinFriendsAndInfluencePeople,
  howWillYouMeasureYourLife,
  htmlCss,
  ikigai,
  infiniteJest,
  influence,
  inPieces,
  inPraiseOfShadows,
  inSearchOfExcellence,
  insight,
  inspired,
  introductionToAlgorithms,
  iOSProgramming,
  javaScriptTheDefinitiveGuide,
  jonathanLivingstonSeagull,
  learningCocoaWithObjectiveC,
  lettersFromAStoic,
  learningNodejs,
  loneSurvivor,
  lordOfTheFlies,
  loveYourselfLikeYourLifeDependsOnIt,
  madeInAmerica,
  madeToStick,
  makeTime,
  makeYourBed,
  makeYourMark,
  mansSearchForMeaning,
  mastersOfDoom,
  meditations,
  memoirsOfAGeisha,
  mindset,
  mythicalManMonth,
  neverEatAlone,
  neverSplitTheDifference,
  objectiveCProgramming,
  objectOrientedJavaScript,
  offTheBeatenTrail,
  onTheShortnessOfLife,
  originals,
  outliers,
  peaceIsEveryStep,
  perennialSeller,
  perfectlyYourself,
  plantBasedHighProteinCookbook,
  playBigger,
  poorCharliesAlmanack,
  pourYourHeartIntoIt,
  principles,
  productLedGrowth,
  programmingInterviewsExposed,
  programmingPearls,
  purpleCow,
  puttingOutOfYourMind,
  quiet,
  radicalAcceptance,
  radicalCandor,
  radicalHope,
  range,
  ravingFans,
  readyForAnything,
  rebirth,
  relentless,
  resistingHappiness,
  rework,
  richDadPoorDad,
  rulesForLife,
  sapiens,
  searchInsideYourself,
  secretSauce,
  seeDoRepeat,
  selfReliance,
  shapeUp,
  shoeDog,
  showYourWork,
  siddhartha,
  slicingPieHandbook,
  smarterFasterBetter,
  soGoodTheyCantIgnoreYou,
  solveForHappy,
  sprint,
  startWithWhy,
  steveJobs,
  stillnessIsTheKey,
  talkingToHumans,
  talkLikeTed,
  taoTeChing,
  tenPercentHappier,
  the10XRule,
  the22ImmutableLawsOfMarketing,
  the48LawsOfPower,
  the4HourBody,
  the4HourChef,
  the4HourWorkweek,
  the7HabitsOfHighlyEffectivePeople,
  theAdvantage,
  theAlchemist,
  theAllegoryOfTheCave,
  theArtOfAsking,
  theArtOfLearning,
  theArtOfStartupFundraising,
  theArtOfTheDeal,
  theArtOfWar,
  theArtSpirit,
  theAutobiographyOfAYogi,
  theBigShort,
  theBodyKeepsTheScore,
  theBulletJournalMethod,
  theCatcherInTheRye,
  theCelestineProphecy,
  theChecklistManifesto,
  theChinaStudy,
  theCircle,
  theCleanCoder,
  theColdStartProblem,
  theCourageToBeDisliked,
  theCreativeAct,
  theCultureCode,
  theDailyStoic,
  theDefiningDecade,
  theDesignOfEverydayThings,
  theDip,
  theDoorsOfPerception,
  theEffectiveExecutive,
  theElementsOfProgrammingStyle,
  theElementsOfStyle,
  theEMythRevisited,
  theEverythingStore,
  theFirstAndLastFreedom,
  theFiveDysfunctionsOfATeam,
  theFourAgreements,
  theFourAgreements2,
  theFourStepsToEpiphany,
  theGiver,
  theGoal,
  theGoGiver,
  theGreatestSalesmanInTheWorld,
  theGreatGatsby,
  theHappinessAdvantage,
  theHardThingAboutHardThings,
  theHitchhikersGuideToTheGalaxy,
  theInnerGameOfTennis,
  theInnovators,
  theInnovatorsDilemma,
  theIntelligentInvestor,
  theIOSInterviewGuide,
  theLastLecture,
  theLeanStartup,
  theLittlePrince,
  theMagicOfThinkingBig,
  theMarketingBlueprint,
  theMartian,
  theMillionaireNextDoor,
  theMomTest,
  theMonkWhoSoldHisFerrari,
  theMountainIsYou,
  theObstacleIsTheWay,
  theOldManAndTheSea,
  theOneMinuteManager,
  theOneThing,
  thePowerLaw,
  thePowerOfHabit,
  thePowerOfMyth,
  thePowerOfNow,
  thePowerOfPositiveThinking,
  thePracticeOfProgramming,
  thePowerOfWritingItDown,
  thePragmaticProgrammer,
  theProphet,
  thePurposeDrivenLife,
  theRemainsOfTheDay,
  theRhythmOfLife,
  theRichestManInBabylon,
  theRoad,
  theRoadToCharacter,
  theRuthlessEliminationOfHurry,
  theScienceOfHitting,
  theScoreTakesCareOfItself,
  theSearchForTruth,
  theSecret,
  theSevenSpiritualLawsOfSuccess,
  theStoicChallenge,
  theSubtleArtOfNotGivingAFck,
  theSurrenderExperiment,
  theSwerve,
  theTaoOfHealthSexAndLongevity,
  theTaoOfPooh,
  theTaoOfWu,
  theTB12Method,
  theThirdDoor,
  theTimelessWayOfBuilding,
  theTippingPoint,
  theUltimateAnabolicCookbook,
  theUnbearableLightnessOfBeing,
  theUntetheredSoul,
  theVirginWay,
  theWarOfArt,
  theWayOfTheSuperiorMan,
  theWayOfZen,
  theWayToLove,
  theWisdomOfLife,
  theWrightBrothers,
  thingsFallApart,
  thinkAndGrowRich,
  thinkingFastAndSlow,
  thinkOnTheseThings,
  thisIsMarketing,
  tinyHabits,
  titlesRuinEverything,
  toKillAMockingBird,
  toolsOfTitans,
  traction,
  tribe,
  trustMeImLying,
  tuesdaysWithMorrie,
  understandingHumanNature,
  vagabonding,
  ventureDeals,
  wabiSabi,
  wakingUp,
  whatITalkAboutWhenITalkAboutRunning,
  whenThingsFallApart,
  whereTheCrawdadsSing,
  whereverYouGoThereYouAre,
  white,
  whiteFragility,
  who,
  whoMovedMyCheese,
  whyWerePolarized,
  whyWeSleep,
  willpower,
  wooden,
  writeItDownMakeItHappen,
  zenMindBeginnersMind,
  zenToDone,
  zeroToOne
];

export const all = [...apps, ...books, ...work, ...writings];

export const tags = books
  .reduce((collection, list) => {
    const ts = list.tags;

    ts.forEach(tag => (collection.includes(tag) ? null : collection.push(tag)));

    return collection;
  }, [])
  .sort();

export const videos = all.filter(a => !!a.youtubeEmbed);
export const podcasts = all.filter(a => !!a.podcastEmbed);
